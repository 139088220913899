import type { Node as PMNode } from '@atlaskit/editor-prosemirror/model';

import type { MarkdownSerializerState, NodeSerializerSpec, NodeSerializerArgs } from './serializer';

const isHeaderRow = (row: PMNode): boolean => row.child(0).type.name === 'tableHeader';

const isFirstRow = (row: PMNode, parent: PMNode): boolean => parent.content.child(0) === row;

const isHeaderRowPresent = (table: PMNode): boolean => {
	let headerRowPresent = false;
	table.content.forEach((row: PMNode) => {
		if (isHeaderRow(row)) {
			headerRowPresent = true;
		}
	});
	return headerRowPresent;
};

const renderNode = (state: MarkdownSerializerState, node: PMNode, index: number): void => {
	if (index > 0) {
		state.write(' ');
	}
	node.content.forEach((child: PMNode, i: number) => {
		if (child.isTextblock || child.type.name === 'mediaSingle') {
			if (i > 0) {
				state.write(' ');
			}
			// Ignored via go/ees005
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(state as any).context.insideTable = true;
			state.renderInline(child);
			// Ignored via go/ees005
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(state as any).context.insideTable = false;
		} else {
			renderNode(state, child, i);
		}
	});
};

const renderInlineContent = ({ state, node }: NodeSerializerArgs) => {
	state.write('| ');
	renderNode(state, node, 0);
	state.write(' ');
};

const tableNodes: NodeSerializerSpec = {
	table: {
		serialize({ state, node, parent, index }: NodeSerializerArgs) {
			if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusAddComplexNodes) {
				const id = state.featureToggles.markdownPlusAvoidDuplicatedIdCounter
					? state.getIdBasedOnIdMap(state, 'table', node.attrs)
					: `id-${state.idCounter++}`;
				state.idMap[id] = {
					type: 'table',
					attributes: node.attrs,
				};
				state.write(`<custom data-type="table_open" data-id="${id}" />\n\n`);

				node.content.forEach((child, i) => state.render(child, node, i));

				state.write(`<custom data-type="table_close" />\n\n`);
			} else {
				const headerRowPresent = isHeaderRowPresent(node);
				node.content.forEach((child, i) => {
					try {
						state.render(child, node, i, {
							headerRowPresent,
							skipFallback: true,
						});
					} catch (error) {
						state.updateFallbackState(error, node);
						state.nodes.fallback.serialize({ state, node, parent, index });
					}
				});
				state.closeBlock(node);
			}
		},
		support: 'partial',
	},
	tableRow: {
		serialize({ state, node, parent, index, options }: NodeSerializerArgs) {
			if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusAddComplexNodes) {
				const id = state.featureToggles.markdownPlusAvoidDuplicatedIdCounter
					? state.getIdBasedOnIdMap(state, 'table', node.attrs)
					: `id-${state.idCounter++}`;
				state.idMap[id] = {
					type: 'table',
					attributes: node.attrs,
				};
				state.write(`<custom data-type="tr_open" data-id="${id}" />\n\n`);
				node.content.forEach((child, i) => {
					try {
						state.render(child, node, i, { skipFallback: true });
					} catch (error) {
						state.updateFallbackState(error, node);
						state.nodes.fallback.serialize({ state, node, parent, index });
					}
				});
				state.write(`<custom data-type="tr_close" />\n\n`);
			} else {
				node.content.forEach((child, i) => {
					try {
						state.render(child, node, i, { ...options, skipFallback: true });
					} catch (error) {
						state.updateFallbackState(error, node);
						state.nodes.fallback.serialize({ state, node, parent, index });
					}
				});
				state.write('|');
				state.ensureNewLine();
				if (isHeaderRow(node) || isFirstRow(node, parent)) {
					for (let i = 0; i < node.childCount; i++) {
						state.write('| --- ');
					}
					state.write('|');
					state.ensureNewLine();
				}
			}
		},
		support: 'partial',
	},
	tableHeader: {
		serialize({ state, node, parent, index, options }: NodeSerializerArgs) {
			if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusAddComplexNodes) {
				const id = state.featureToggles.markdownPlusAvoidDuplicatedIdCounter
					? state.getIdBasedOnIdMap(state, 'table', node.attrs)
					: `id-${state.idCounter++}`;
				state.idMap[id] = {
					type: 'table',
					attributes: node.attrs,
				};
				state.write(`<custom data-type="th_open" data-id="${id}" />\n\n`);
				node.content.forEach((child, i) => {
					try {
						state.render(child, node, i, { skipFallback: true });
					} catch (error) {
						state.updateFallbackState(error, node);
						state.nodes.fallback.serialize({ state, node, parent, index });
					}
				});
				state.write(`<custom data-type="th_close" />\n\n`);
			} else {
				renderInlineContent({ state, node, parent, index, options });
			}
		},
		support: 'partial',
	},
	tableCell: {
		serialize({ state, node, parent, index, options }: NodeSerializerArgs) {
			if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusAddComplexNodes) {
				const id = state.featureToggles.markdownPlusAvoidDuplicatedIdCounter
					? state.getIdBasedOnIdMap(state, 'table', node.attrs)
					: `id-${state.idCounter++}`;
				state.idMap[id] = {
					type: 'table',
					attributes: node.attrs,
				};
				state.write(`<custom data-type="td_open" data-id="${id}" />\n\n`);
				node.content.forEach((child, i) => {
					try {
						state.render(child, node, i, { skipFallback: true });
					} catch (error) {
						state.updateFallbackState(error, node);
						state.nodes.fallback.serialize({ state, node, parent, index });
					}
				});
				state.write(`<custom data-type="td_close" />\n\n`);
			} else {
				renderInlineContent({ state, node, parent, index, options });
			}
		},
		support: 'partial',
	},
};

export default tableNodes;
